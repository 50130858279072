<template>
  <div class="mobile-app">
    <ym-mobile-header />
    <scroll ref="scroll" class="scroll mobile-scroll" :data="scrollData" :scrollbar="false">
      <div class="app-main">
        <nuxt />
        <ym-mobile-footer @returnTop="returnTop"/>
      </div>
    </scroll>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import YmMobileHeader from '@/components/YmMobileHeader.vue'
import YmMobileFooter from '@/components/YmMobileFooter.vue'
export default {
  data() {
    return {
      scrollData: Math.random(),
    }
  },
  components: {
    YmMobileHeader,
    YmMobileFooter
  },
  mounted() {
    setInterval(() => {
      this.scrollData = Math.random()
    }, 1000);
  },
  methods: {
    returnTop(duration) {
      this.$refs.scroll.scrollTo(0,0, duration)
    },
    ...mapMutations([

    ]),
    ...mapActions([

    ]),
  },
  computed: {
    ...mapGetters([

    ])
  },
  watch: {
    $route: {
      async handler(v){
        this.$refs.scroll.scrollTo(0,0)
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
// @import '~@/temp.scss';
html,body,#__nuxt,  #__layout, .mobile-app {
  height: 100%;
  background: #fff;
  overflow: hidden;
}
.mobile-scroll {
  height: 100%;
}
.app-main {
  padding-top: 2.5rem;
}
</style>
