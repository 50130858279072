<template>
  <div class="app">
    <nuxt />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
  name: 'emptyLayout',
  data() {
    return {

    }
  },
  components: {

  },
  mounted() {

  },
  methods: {
    ...mapMutations({

    }),
    ...mapActions({

    }),
  },
  computed: {
    ...mapGetters({

    })
  }
}
</script>

<style scoped lang="scss">
// @import '~@/temp.scss';
html,body,#__nuxt,  #__layout, .app {
  height: 100%;
  background: #fff;
  overflow: hidden;
}
</style>
