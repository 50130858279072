<template>
  <div class="app" @scroll="scroll" ref="scroll">
    <!-- <scroll
      ref="scroll"
      class="scroll"
      :data="scrollData"
      :listenScroll="true"
      @scroll="scroll"
    > -->
    <div class="app-main">
      <ym-header v-show="headerView" />
      <nuxt />
      <ym-footer />
    </div>
    <!-- </scroll> -->
    <span class="go-top-btn" @click="goTop()" v-if="showGoTopBtn"></span>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import YmHeader from "@/components/YmHeader.vue";
import YmFooter from "@/components/YmFooter.vue";
export default {
  data() {
    return {
      scrollData: Math.random(),
      showGoTopBtn: false,
      timer: null,
      scrollY: false,
      headerView: true,
    };
  },
  components: {
    YmHeader,
    YmFooter,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      console.log(vm.$route);
    });
  },
  mounted() {
    setInterval(() => {
      this.scrollData = Math.random();
    }, 1000);
  },
  methods: {
    goTop() {
      this.$refs.scroll.scrollTo(0, 0, 800);
    },
    closeScroll() {
      this.scrollY = false;
    },
    scroll(e) {
      this.scrollY = true;
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(this.closeScroll, 2000);
      if (Math.abs(e.target.scrollTop) > 100) {
        this.showGoTopBtn = true;
      } else {
        this.showGoTopBtn = false;
      }
      if (Math.abs(e.target.scrollTop) > 80) {
        this.headerView = false;
      } else {
        this.headerView = true;
      }
    },
    ...mapMutations(["setScrollToTop"]),
  },
  computed: {
    ...mapGetters(["scrollToTop"]),
  },
  watch: {
    $route: {
      async handler(v) {
        this.$refs.scroll.scrollTo(0, 0);
      },
      deep: true,
    },
    async scrollToTop(v) {
      if (v) {
        this.$refs.scroll.scrollTo(0, 0);
        await this.$utils.sleep(1000);
        this.setScrollToTop(false);
      }
    },
  },
};
</script>

<style>
html,
body,
#__nuxt,
#__layout,
.app {
  height: 100%;
  background: #fff;
  overflow: auto;
  --scroll-width: 10px;
}
.change {
  --scroll-width: 10px !important;
}
.go-top-btn {
  position: fixed;
  width: 52px;
  height: 52px;
  bottom: 50px;
  right: 30px;
  background: url("~assets/img/home/go_top.png") no-repeat;
  cursor: pointer;
  z-index: 99;
}
::-webkit-scrollbar {
  width: var(--scroll-width);
  height: 16px;
  background-color: #f5f5f5;
  position: absolute;
  transition: width 0.5s;
}
/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgb(184, 184, 184);
}
</style>
