<template>
  <div class="mobile-header-container" :class="{open: isOpen}">
    <div class="header-main">
      <span class="logo"></span>
      <span class="fold-btn" @click="switchPanel"></span>      
    </div>
    <scroll class="scroll header-fold-scroll" :data="scrollData">
      <div class="header-fold-main">
        <ul class="navbar-list">
          <li v-for="navbar in navbarList" :key="navbar.name" :class="{open: navbar.isOpen}">
            <div class="navbar-item-top" @click="switchNavbarPanel(navbar)">
              <span class="navbar-title">{{navbar.name}}</span>
              <span class="navbar-arrow el-icon-arrow-right" v-if="navbar.arrowIcon"></span>
              <span class="navbar-plus el-icon-plus" v-if="navbar.closeIcon"></span>
            </div>
            <ul class="navbar-item-list" v-if="navbar.children && navbar.children.length">
              <li v-for="item in navbar.children" :key="item.name" @click="switchPage(item)">{{item.name}}</li>
            </ul>
          </li>
        </ul>
      </div>
    </scroll>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
  name: 'YmMobileHeader',
  data() {
    return {
      scrollData: Math.random(),
      isOpen: false,
      navbarList: [
        {
          name: '首页',
          isOpen: false,
          arrowIcon: true,
          closeIcon: false,
          link: '/mobile'
        },
        {
          name: '关于我们',
          isOpen: false,
          arrowIcon: true,
          closeIcon: false,
          link: '/mobile/aboutus'
        },
        {
          name: '产品中心',
          isOpen: false,
          arrowIcon: false,
          closeIcon: true,
          children: [
            {
              name: '智能家居',
              link: '/mobile/product/house'
            },
            {
              name: '智能工业',
              link: '/mobile/product/industry'
            }
          ]
        },
        {
          name: '案例展示',
          isOpen: false,
          arrowIcon: true,
          closeIcon: false,
          link: '/mobile/case'
        },
        {
          name: '招贤纳士',
          isOpen: false,
          arrowIcon: true,
          closeIcon: false,
          link: '/mobile/recruit'
        },
      ]
    }
  },
  components: {

  },
  mounted() {

  },
  methods: {
    switchPage(item) {
      if (item.link) {
        this.$router.push({path: item.link})
        this.isOpen = false
      }
    },
    switchNavbarPanel(navbar) {
      if (navbar.link) {
        this.$router.push({path: navbar.link})
        this.isOpen = false
      } else {
        navbar.isOpen = !navbar.isOpen
        this.scrollData = Math.random()
      }
    },
    switchPanel() {
      this.isOpen = !this.isOpen
    },
    ...mapMutations([

    ]),
    ...mapActions([

    ]),
  },
  computed: {
    ...mapGetters([

    ])
  }
}
</script>

<style scoped lang="scss">
// @import '~@/temp.scss';
.mobile-header-container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background: #191919;
  z-index: 99;
  height: 2.5rem;
  transition: all .5s;
  &.open {
    height: 100%;
    .header-main {
      border-bottom: 1px solid #F7F7F7;
    }
  }
}
.header-main {
  display: flex;
  align-items: center;
  justify-content: center;  
  height: 2.5rem;
  position: relative;
}
.logo {
  width: 2.75rem;
  height: .8rem;
  background: url('~assets/img/mobile/home/logo.png') no-repeat;
  background-size: contain;
}
.fold-btn {
  width: 1rem;
  height: .75rem;
  position: absolute;
  left: .8rem;
  top: 50%;
  transform: translateY(-50%);
  background: url('~assets/img/mobile/home/header_fold_icon.png') no-repeat;
  background-size: contain;
}
.header-fold-scroll {
  height: calc(100% - 2.5rem);
}
.navbar-list {
  background: #191919;
  padding: 0 .8rem;
  >li {
    &.open {
      &:nth-of-type(3) {
        .navbar-item-list {
          height: 4.6rem;
        }
      }
      .navbar-plus  {
        transform: rotate(45deg);
      }
    }
  }
}
.navbar-item-top {
  height: 2.3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #484848;
}
.navbar-title {
  color: #fff;
  font-size: .6rem;
}
.navbar-arrow {
  color: #F7F7F7;
  font-size: .7rem;
}
.navbar-plus {
  color: #F7F7F7;
  font-size: .7rem;
  transition: all .3s;
}
.navbar-item-list {
  padding-left: .8rem;
  height: 0;
  overflow: hidden;
  transition: all .3s;
  >li {
    height: 2.3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #484848;
    color: #fff;
    font-size: .6rem;
  }
}
</style>
