<template>
  <div class="footer-container">
    <div class="footer-main">
      <div class="footer-left">
        <div class="contact-way">
          <h5>联系方式</h5>
          <p>(0574) 8187 9827</p>
        </div>
        <div class="contact-item workday">工作日：周一至周五 8:30-17:30</div>
        <div class="contact-item">邮箱: service@yimuzn.com</div>
        <div class="contact-item address">地址：宁波市鄞州区宁南北路802号（慧和大厦裙楼303室）</div>
        <!-- <ul class="link-list">
          <li>
            <img src="~/assets/img/home/weibo.png" alt="" widtd="39px" height="39px">
          </li>
          <li>
            <img src="~/assets/img/home/qq.png" alt="" widtd="39px" height="39px">
          </li>
          <li>
            <img src="~/assets/img/home/wx_public.png" alt="" widtd="39px" height="39px">
          </li>
        </ul> -->
        <div class="put-on-records">
          <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备19021646号-1</a>
          <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33021202001134" target="_blank">浙公网安备 33021202001134号</a>
        </div>
      </div>
      <ul class="footer-menu">
        <li>
          <h5>产品中心</h5>
          <p @click="() => {$router.push({path: '/product/house'})}">智能家居</p>
          <p @click="() => {$router.push({path: '/product/industry'})}">智能工业</p>
        </li>
        <li>
          <h5>案例展示</h5>
          <p @click="() => {$router.push({path: '/case', query: {type: 0}})}">智能家居</p>
          <p @click="() => {$router.push({path: '/case', query: {type: 1}})}">智能工业</p>
        </li>
        <li>
          <h5>招贤纳士</h5>
          <p @click="() => {$router.push({path: '/recruit/list', query: {type: 1}})}">社会招聘</p>
          <p @click="() => {$router.push({path: '/recruit/list', query: {type: 0}})}">校园招聘</p>
        </li>
        <!-- <li>
          <h5>工业解决方案</h5>
          <p @click="() => {$router.push({path: '/solution/industry/loading-and-unloading'})}">机器人视觉及智能上下料</p>
          <p @click="() => {$router.push({path: '/solution/industry/production-state'})}">生产状态智能检测</p>
          <p @click="() => {$router.push({path: '/solution/industry/identification'})}">表面瑕疵系统</p>
        </li>
        <li>
          <h5>家居解决方案</h5>
          <p @click="() => {$router.push({path: '/solution/house/smart-home'})}">智能全宅系统</p>
          <p @click="() => {$router.push({path: '/solution/house/big-data'})}">智能大数据</p>
          <p @click="() => {$router.push({path: '/solution/house/provide-for-the-aged'})}">智能安防系统</p>
        </li>
        <li>
          <h5>产品中心</h5>
          <p @click="() => {$router.push({path: '/product/industry'})}">工业产品端</p>
          <p @click="() => {$router.push({path: '/product/house'})}">智能家居产品</p>
        </li>
        <li>
          <h5>关于一木</h5>
          <p @click="() => {$router.push({path: '/aboutus/profile'})}">公司简介</p>
          <p>加入一木</p>
          <p @click="() => {$router.push({path: '/aboutus/news'})}">新闻中心</p>
        </li> -->
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
  name: 'name',
  data() {
    return {

    }
  },
  components: {

  },
  mounted() {

  },
  methods: {
    ...mapMutations([

    ]),
    ...mapActions([

    ]),
  },
  computed: {
    ...mapGetters([

    ])
  }
}
</script>

<style scoped lang="scss">
// @import '~@/temp.scss';
.footer-container {
  height: 415px;
  background: #2C2C2C;
}
.footer-main {
  width: 1200px;
  height: 415px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.footer-left {
  padding-top: 48px;
}
.contact-way {
  >h5 {
    @include fh(16px);
    color: #fff;
    font-weight:400;
  }
  >p {
    @include fh(23px);
    color: #fff;
    margin-top: 12px;
    font-weight:400;
  }
}
.contact-item {
  font-size: 14px;
  color: rgba(255, 255, 255, .6);
  margin-top: 10px;
  line-height: 1.5;
  &.workday {
    margin-top: 58px;
  }
  &.address {
    margin-top: 12px;
    width: 202px;
  }
}
.link-list {
  margin-top: 30px;
  display: flex;
  align-items: center;
  >li {
    margin-right: 16px;
    img {
      border-radius: 50%;
    }
  }
}
.put-on-records {
  margin-top: 50px;
  >a, span {
    color: rgba(255, 255, 255, .6);
    font-size: 12px;
  }
}
.footer-menu {
  padding-top: 48px;
  display: flex;
  >li {
    margin-right: 30px;
    &:nth-of-type(1) {
      width: 160px;
    }
    &:nth-of-type(2) {
      width: 160px;
    }
    &:nth-of-type(3) {
      width: 160px;
    }
    >h5 {
      @include fh(16px);
      color: #fff;
      margin-bottom: 30px;
    }
    >p {
      font-size: 14px;
      color: rgba(255, 255, 255, .6);
      margin-bottom: 12px;
      cursor: pointer;
      &:hover {
        color: #1C88FF;
      }
    }
  }
}
@media screen and (min-width: 1280px) {
}
@media screen and (min-width: 991px) and (max-width: 1280px) {
}
@media screen and (min-width: 768px) and (max-width: 991px) {
}
@media screen and (max-width: 767px) {

}

</style>
