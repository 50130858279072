/**
 * 主模块
 */
// import Article from '@/api/request/Article.js';

export const state = () => ({
  scrollToTop: false,
})

export const getters = {
  scrollToTop: state => state.scrollToTop
}

export const mutations = {
  setScrollToTop(state, scrollToTop) {
    state.scrollToTop = scrollToTop;
  },
}

export const actions = {
  nuxtServerInit ({ commit }, { req }) {
    // console.log('server init')
    // let cookie = req.headers.cookie;
    // console.log(cookie)
    // let formatCookie = this.$utils.Cookie2Json(cookie);
    // let token = formatCookie.token;
    // commit('setToken', token);
  },

}
