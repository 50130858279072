import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5cc09d6c = () => interopDefault(import('..\\src\\pages\\aboutus\\index.vue' /* webpackChunkName: "pages_aboutus_index" */))
const _563fe2e6 = () => interopDefault(import('..\\src\\pages\\case\\index.vue' /* webpackChunkName: "pages_case_index" */))
const _625b951b = () => interopDefault(import('..\\src\\pages\\mobile\\index.vue' /* webpackChunkName: "pages_mobile_index" */))
const _7770b739 = () => interopDefault(import('..\\src\\pages\\recruit\\index.vue' /* webpackChunkName: "pages_recruit_index" */))
const _715c182a = () => interopDefault(import('..\\src\\pages\\test\\index.vue' /* webpackChunkName: "pages_test_index" */))
const _eb168d92 = () => interopDefault(import('..\\src\\pages\\case\\pipeline.vue' /* webpackChunkName: "pages_case_pipeline" */))
const _664b0651 = () => interopDefault(import('..\\src\\pages\\case\\scienceMuseum.vue' /* webpackChunkName: "pages_case_scienceMuseum" */))
const _432b6383 = () => interopDefault(import('..\\src\\pages\\case\\threePhasePower.vue' /* webpackChunkName: "pages_case_threePhasePower" */))
const _2fbe178e = () => interopDefault(import('..\\src\\pages\\mobile\\aboutus\\index.vue' /* webpackChunkName: "pages_mobile_aboutus_index" */))
const _ae50d0aa = () => interopDefault(import('..\\src\\pages\\mobile\\case\\index.vue' /* webpackChunkName: "pages_mobile_case_index" */))
const _4a6e315b = () => interopDefault(import('..\\src\\pages\\mobile\\recruit\\index.vue' /* webpackChunkName: "pages_mobile_recruit_index" */))
const _c40b0098 = () => interopDefault(import('..\\src\\pages\\mobile\\resume.vue' /* webpackChunkName: "pages_mobile_resume" */))
const _4d135f40 = () => interopDefault(import('..\\src\\pages\\mobile\\solution.vue' /* webpackChunkName: "pages_mobile_solution" */))
const _d452f8aa = () => interopDefault(import('..\\src\\pages\\product\\detail.vue' /* webpackChunkName: "pages_product_detail" */))
const _f31f1f94 = () => interopDefault(import('..\\src\\pages\\product\\house.vue' /* webpackChunkName: "pages_product_house" */))
const _5799d638 = () => interopDefault(import('..\\src\\pages\\product\\industry.vue' /* webpackChunkName: "pages_product_industry" */))
const _0ef93f7a = () => interopDefault(import('..\\src\\pages\\recruit\\detail.vue' /* webpackChunkName: "pages_recruit_detail" */))
const _9f3a0cf2 = () => interopDefault(import('..\\src\\pages\\recruit\\list.vue' /* webpackChunkName: "pages_recruit_list" */))
const _4903035b = () => interopDefault(import('..\\src\\pages\\case\\components\\caseCarousel.vue' /* webpackChunkName: "pages_case_components_caseCarousel" */))
const _5d723359 = () => interopDefault(import('..\\src\\pages\\mobile\\case\\pipeline.vue' /* webpackChunkName: "pages_mobile_case_pipeline" */))
const _52ae916f = () => interopDefault(import('..\\src\\pages\\mobile\\case\\scienceMuseum.vue' /* webpackChunkName: "pages_mobile_case_scienceMuseum" */))
const _b64ec1be = () => interopDefault(import('..\\src\\pages\\mobile\\case\\threePhasePower.vue' /* webpackChunkName: "pages_mobile_case_threePhasePower" */))
const _596dea58 = () => interopDefault(import('..\\src\\pages\\mobile\\product\\house.vue' /* webpackChunkName: "pages_mobile_product_house" */))
const _7f0190d6 = () => interopDefault(import('..\\src\\pages\\mobile\\product\\industry.vue' /* webpackChunkName: "pages_mobile_product_industry" */))
const _c8a9ecd0 = () => interopDefault(import('..\\src\\pages\\mobile\\recruit\\detail.vue' /* webpackChunkName: "pages_mobile_recruit_detail" */))
const _7af1c81e = () => interopDefault(import('..\\src\\pages\\mobile\\recruit\\society.vue' /* webpackChunkName: "pages_mobile_recruit_society" */))
const _dc797456 = () => interopDefault(import('..\\src\\pages\\mobile\\recruit\\university.vue' /* webpackChunkName: "pages_mobile_recruit_university" */))
const _ff914506 = () => interopDefault(import('..\\src\\pages\\index.vue' /* webpackChunkName: "pages_index" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
      path: "/aboutus",
      component: _5cc09d6c,
      name: "aboutus"
    }, {
      path: "/case",
      component: _563fe2e6,
      name: "case"
    }, {
      path: "/mobile",
      component: _625b951b,
      name: "mobile"
    }, {
      path: "/recruit",
      component: _7770b739,
      name: "recruit"
    }, {
      path: "/test",
      component: _715c182a,
      name: "test"
    }, {
      path: "/case/pipeline",
      component: _eb168d92,
      name: "case-pipeline"
    }, {
      path: "/case/scienceMuseum",
      component: _664b0651,
      name: "case-scienceMuseum"
    }, {
      path: "/case/threePhasePower",
      component: _432b6383,
      name: "case-threePhasePower"
    }, {
      path: "/mobile/aboutus",
      component: _2fbe178e,
      name: "mobile-aboutus"
    }, {
      path: "/mobile/case",
      component: _ae50d0aa,
      name: "mobile-case"
    }, {
      path: "/mobile/recruit",
      component: _4a6e315b,
      name: "mobile-recruit"
    }, {
      path: "/mobile/resume",
      component: _c40b0098,
      name: "mobile-resume"
    }, {
      path: "/mobile/solution",
      component: _4d135f40,
      name: "mobile-solution"
    }, {
      path: "/product/detail",
      component: _d452f8aa,
      name: "product-detail"
    }, {
      path: "/product/house",
      component: _f31f1f94,
      name: "product-house"
    }, {
      path: "/product/industry",
      component: _5799d638,
      name: "product-industry"
    }, {
      path: "/recruit/detail",
      component: _0ef93f7a,
      name: "recruit-detail"
    }, {
      path: "/recruit/list",
      component: _9f3a0cf2,
      name: "recruit-list"
    }, {
      path: "/case/components/caseCarousel",
      component: _4903035b,
      name: "case-components-caseCarousel"
    }, {
      path: "/mobile/case/pipeline",
      component: _5d723359,
      name: "mobile-case-pipeline"
    }, {
      path: "/mobile/case/scienceMuseum",
      component: _52ae916f,
      name: "mobile-case-scienceMuseum"
    }, {
      path: "/mobile/case/threePhasePower",
      component: _b64ec1be,
      name: "mobile-case-threePhasePower"
    }, {
      path: "/mobile/product/house",
      component: _596dea58,
      name: "mobile-product-house"
    }, {
      path: "/mobile/product/industry",
      component: _7f0190d6,
      name: "mobile-product-industry"
    }, {
      path: "/mobile/recruit/detail",
      component: _c8a9ecd0,
      name: "mobile-recruit-detail"
    }, {
      path: "/mobile/recruit/society",
      component: _7af1c81e,
      name: "mobile-recruit-society"
    }, {
      path: "/mobile/recruit/university",
      component: _dc797456,
      name: "mobile-recruit-university"
    }, {
      path: "/",
      component: _ff914506,
      name: "index"
    }],

  fallback: false
}

export function createRouter() {
  return new Router(routerOptions)
}
