<template>
  <div class="header-warp">
    <div class="header-main">
      <ul class="navbar-wrap">
        <!-- <li :class="{active: activeIndex===1}" @click="switchTab(1)"><span class="navbar-text">首页</span></li> -->
        <li @click="switchTab(1)">
            <img src="~/assets/img/home/logo.png" class="logo" width="88px" height="26px" alt="">
        </li>
        <li :class="{active: activeIndex===2}" @click="switchTab(2)">
          <span class="navbar-text">关于我们</span>
          <!-- <div class="navbar-popover aboutus-popover">
            <span @click="switchTab(2, 1)">一木简介</span>
            <span @click="switchTab(2, 2)">新闻中心</span>
          </div> -->
        </li>
        <li :class="{active: activeIndex===3}" @click="switchTab(3)">
          <span class="navbar-text">产品中心</span>
          <div class="navbar-popover product-popover">
            <span @click="switchTab(3, 1)">智能家居</span>
            <span @click="switchTab(3, 2)">智能工业</span>
          </div>
        </li>
        <li :class="{active: activeIndex===4}" @click="switchTab(4)">
          <span class="navbar-text">案例展示</span>
          <!-- <div class="navbar-popover solution-popover">
            <div class="solution-popover-main">
              <div class="solution-popover-item smart-industry">
                <h5>智能工业</h5>
                <div class="solution-popover-content">
                  <span class="tab-text" @click="switchTab(4, 6)">视觉检测系统</span>
                  <span class="tab-text" @click="switchTab(4, 7)">瑕疵识别系统</span>
                  <span class="tab-text" @click="switchTab(4, 8)">生产状态智能检测</span>
                </div>
              </div>
              <div class="solution-popover-item smart-home">
                <h5>智能家居</h5>
                <div class="solution-popover-content">
                  <span class="tab-text" @click="switchTab(4, 1)">家居生态系统</span>
                  <span class="tab-text" @click="switchTab(4, 2)">智能大数据</span>
                  <span class="tab-text" @click="switchTab(4, 4)">智能健康系统</span>
                </div>
              </div>
            </div>
          </div> -->
        </li>
        <li :class="{active: activeIndex===5}" @click="switchTab(5)">
          <span class="navbar-text">招贤纳士</span>
          <!-- <div class="navbar-popover recruit-popover">
            <span @click="switchTab(5, 1)">校园招聘</span>
            <span @click="switchTab(5, 2)">社会招聘</span>
          </div> -->
        </li>
      </ul>
      <!-- <div class="navbar-right">
        <div class="switch-lang">
          <i class="switch-lang-icon"></i>
          <el-select v-model="lang" class="lang-select" popper-class="lang-select-popper">
            <el-option
              v-for="item in langOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div> -->
        <!-- <div class="login-register-wrap">
          <span class="login-btn">登录</span>
          <span class="register-btn">注册</span>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
  name: 'YmHeader',
  data() {
    return {
      activeIndex: 1,
      lang: 'chinese',
      langOptions: [
        {
          value: 'chinese',
          label: '中文',
        },
        {
          value: 'english',
          label: 'English'
        }
      ]
    }
  },
  mounted() {
    this.initNavbar()
  },
  methods: {
    initNavbar() {
      let fullPath = this.$route.fullPath
      if (fullPath === '/') {
        this.activeIndex = 1
      } else if (fullPath.includes('aboutus')) {
        this.activeIndex = 2
      } else if (fullPath.includes('product')) {
        this.activeIndex = 3
      } else if (fullPath.includes('case')) {
        this.activeIndex = 4
      } else if (fullPath.includes('recruit')) {
        this.activeIndex = 5
      }
    },
    switchTab(tabIndex, subIndex) {
      this.activeIndex = tabIndex
      switch(tabIndex) {
        case 1:
          this.$router.push({path: '/'})
        break;
        case 2:
          this.$router.push({path: '/aboutus'})
          // if (subIndex === 1) {
          //   this.$router.push({path: '/aboutus/profile'})
          // } else if (subIndex === 2) {
          //   this.$router.push({path: '/aboutus/news'})
          // }
        break;
        case 3:
          if (subIndex === 1) {
            this.$router.push({path: '/product/house'})
          } else if (subIndex === 2) {
            this.$router.push({path: '/product/industry'})
          }
        break;
        case 4:
          this.$router.push({path: '/case'})
        break;
        case 5:
          this.$router.push({path: '/recruit'})
        break;
        default:
        break;
      }
      // if (tabIndex===4 && subIndex) {
      //   switch(subIndex) {
      //     case 1:
      //       this.$router.push({path: '/solution/house/smart-home'})
      //     break;
      //     case 2:
      //       this.$router.push({path: '/solution/house/big-data'})
      //     break;
      //     case 3:
            
      //     break;
      //     case 4:
      //       this.$router.push({path: '/solution/house/provide-for-the-aged'})
      //     break;
      //     case 5:
            
      //     break;
      //     case 6:
      //       this.$router.push({path: '/solution/industry/loading-and-unloading'})
      //     break;
      //     case 7:
      //       this.$router.push({path: '/solution/industry/identification'})
      //     break;
      //     case 8:
      //       this.$router.push({path: '/solution/industry/production-state'})
      //     break;
      //     default:
      //     break;
      //   }
      // }
    },
    ...mapMutations([

    ]),
    ...mapActions([

    ]),
  },
  watch: {
    $route: {
      async handler(v){
        this.initNavbar()
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters([

    ])
  }
}
</script>

<style scoped lang="scss">
// @import '~@/temp.scss';
.header-warp {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 63px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, .15);
  background: transparent;
  z-index: 99;
  user-select: none;
  transition: background .5s;
  &:hover {
    background: #2C2C2C;
  }
}
.header-main {
  width: 100%;
  margin: 0 auto;
  height: 63px;
  display: flex;
  align-items: center;
  background: transparent;
  position: relative;
  padding-left: 100px;
}
.logo {
  width: 88px;
  height: 26px;
  vertical-align: middle;
}
.navbar-wrap {
  margin-left: 92px;
  display: flex;
  align-items: center;
  >li {
    width: 85px;
    height: 63px;
    line-height: 63px;
    text-align: center;
    cursor: pointer;
    margin-right: 15px;
    position: relative;
    margin: 0 16px;
    &:first-of-type {
      margin-left: 0;
    }
    &.active {
      &:before {
        width: 100%;
        opacity: 1;
      }
    }
    &:before {
      opacity: 0;
      width:0px;
      height:2px;
      content: '';
      position: absolute;
      background:#1C88FF;
      left:0px;
      bottom:0px;
      transition:all 0.5s;
    }
    &:hover, &.temp {
      &:before {
        width: 100%;
        opacity: 1;
      }
      .navbar-text {
        color: #fff;
      }
      .navbar-popover {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
.navbar-text {
  color: rgba(255, 255, 255, .8);
  font-size: 14px;
}
.navbar-popover {
  background: #2C2C2C;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s;
}
.navbar-right {
  position: absolute;
  top: 0;
  right: 0;
  height: 63px;
  display: flex;
  align-items: center;
  padding-right: 200px;
}
.switch-lang {
  display: flex;
  align-items: center;
}
.switch-lang-icon {
  width: 15px;
  height: 15px;
  background: url('~assets/img/home/chinese_icon.png') no-repeat;
}
.lang-select {
  margin-left: 5px;
  /deep/ .el-input {
    background: transparent;
    width: 78px;
    input {
      background: transparent;
      border: none;
      color: #fff;
      padding-left: 0;
      text-align: center;
    }
    .el-input__icon {
      color: #fff;
    }
  } 
}
.login-register-wrap {
  display: flex;
  align-items: center;
  margin-left: 60px;

}
.login-btn {
  color: rgba(255, 255, 255, .8);
  cursor: pointer;
  padding: 0 8px;
  height: 15px;
  line-height: 15px;
  font-size: 15px;;
}
.register-btn {
  @extend .login-btn;
  border-left: 1px solid #fff;
}
.solution-popover {
  display: flex;
  padding: 20px;
  padding-top: 37px;
  position: fixed;
  left: 0;
  right: 0;
  top: 63px;
}
.solution-popover-main {
  width: 1300px;
  margin: 0 auto;
  display: flex;
}
.solution-popover-item {
  >h5 {
    @include fh(16px);
    color: rgba(255, 255, 255, .6);
    font-weight: 400;
    text-align: left;
  }
  &.smart-home {
    flex: 1;
    >h5 {
      padding-left: 84px;
    }
    .solution-popover-content {
      display: flex;
      flex-direction: column;
      .tab-text {
        @include fh(14px);
        color: #fff;
        text-align: left;
        margin-bottom: 24px;
        padding-left: 84px;
        &:last-of-type {
          margin-bottom: 0;
        }
        &:hover {
          color: #1C88FF;
        }
      }
    }
  }
  &.smart-industry {
    width: 256px;
    .solution-popover-content {
      border-right: 1px solid rgba(255, 255, 255, .1);
      display: flex;
      flex-wrap: wrap;
      .tab-text {
        width: 100%;
        @include fh(14px);
        color: #fff;
        text-align: left;
        &:hover {
          color: #1C88FF;
        }
      }
    }
  }  
}
.solution-popover-content {
  height: 120px;
  margin-top: 37px;
}
.aboutus-popover {
  width: 204px;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  position: relative;
  left: -20px;
  >span {
    @include fh(14px);
    color: #fff;
    text-align: left;
    margin-bottom: 25px;
    padding-left: 32px;
    &:last-of-type {
      margin-bottom: 0;
    }
    &:hover {
      color: #1C88FF;
    }
  }
}
.product-popover {
  width: 204px;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  position: relative;
  left: -20px;
  >span {
    @include fh(14px);
    color: #fff;
    text-align: left;
    margin-bottom: 25px;
    padding-left: 32px;
    &:last-of-type {
      margin-bottom: 0;
    }
    &:hover {
      color: #1C88FF;
    }
  }
}
.recruit-popover {
  width: 204px;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  position: relative;
  left: -20px;
  >span {
    @include fh(14px);
    color: #fff;
    text-align: left;
    margin-bottom: 25px;
    padding-left: 32px;
    &:last-of-type {
      margin-bottom: 0;
    }
    &:hover {
      color: #1C88FF;
    }
  }
}
</style>
