export default function (ctx) {
  const userAgentInfo = process.server ? ctx.req.headers['user-agent'] : navigator.userAgent
  const url = process.server ? ctx.req.url : location.pathname
  const agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"]
  let isPc = true
  
  for (let agent of agents) {
    if (userAgentInfo.includes(agent)) {
      isPc = false
    }
  }
  if (isPc && url.includes('mobile')) {
    ctx.redirect('/')
  } else if (!isPc && !url.includes('mobile')) {
    ctx.redirect('/mobile')
  }
}
