import uuidv1 from 'uuid/v1'
export function sleep(time) {
  return new Promise((resolve) => {
    let timer = setTimeout(() => {
      clearTimeout(timer)
      resolve()
    }, time)
  })
}

export function getUniqueId() {
  return `fe-${uuidv1()}`
}
