<template>
  <div class="mobile-footer-container">
    <div class="return-btn" @click="returnTop">
      <span>回到顶部</span>
      <i class="el-icon-arrow-up"></i>
    </div>
    <ul class="nav-list">
      <li v-for="nav in navList" :key="nav.name" :class="{open: nav.isOpen}">
        <div class="navbar-item-top" @click="switchNavbarPanel(nav)">
          <span class="navbar-title">{{nav.name}}</span>
          <span class="navbar-plus el-icon-plus"></span>
        </div>
        <ul class="navbar-item-list" v-if="nav.children && nav.children.length">
          <li v-for="item in nav.children" :key="item.name" @click="switchPage(item)">{{item.name}}</li>
        </ul>
      </li>
    </ul>
    <div class="contact-wrap">
      <div class="contact-way-title">联系方式</div>
      <div class="contact-way-content">(0574) 8187 9827</div>
      <div class="email-text">邮箱: service@yimuzn.com</div>
      <div class="workday-text">工作日：周一至周五 8:30-17:30</div>
      <div class="address-text">地址：宁波市鄞州区宁南北路802号（慧和大厦裙楼303室）</div>
      <div class="put-on-records">
          <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备19021646号-1</a>
          <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33021202001134" target="_blank">浙公网安备 33021202001134号</a>
        </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
  name: 'YmMobileFooter',
  data() {
    return {
      navList: [
        {
          name: '产品中心',
          isOpen: false,
          children: [
            {
              name: '智能家居',
              link: '/mobile/product/house'
            },
            {
              name: '智能工业',
              link: '/mobile/product/industry'
            }
          ]
        },
        {
          name: '案例展示',
          isOpen: false,
          children: [
            {
              name: '智能家居',
              link: '/mobile/case',
              query: {
                type: 0
              }
            },
            {
              name: '智能工业',
              link: '/mobile/case',
              query: {
                type: 1
              }
            }
          ]
        },
        {
          name: '招贤纳士',
          isOpen: false,
          children: [
            {
              name: '社会招聘',
              link: '/mobile/recruit/society'
            },
            {
              name: '校园招聘',
              link: '/mobile/recruit/university'
            }
          ]
        }
      ]
    }
  },
  components: {

  },
  mounted() {

  },
  methods: {
    switchPage(item) {
      if (item.link) {
        if (item.query) {
          this.$router.push({path: item.link, query: item.query })
        } else {
          this.$router.push({path: item.link})
        }
        this.$emit('returnTop', 0)
      }
    },
    returnTop() {
      this.$emit('returnTop', 800)
    },
    switchNavbarPanel(nav) {
      nav.isOpen = !nav.isOpen
    },
    ...mapMutations([

    ]),
    ...mapActions([

    ]),
  },
  computed: {
    ...mapGetters([

    ])
  }
}
</script>

<style scoped lang="scss">
// @import '~@/temp.scss';
.mobile-footer-container {
  background: #191919;
}
.return-btn {
  height: 2.5rem;
  border-bottom: 1px solid #515151;
  display: flex;
  align-items: center;
  justify-content: center;
  >span {
    color: #fff;
    font-size: .6rem;
  }
  >i {
    color: #FAFAFA;
    font-size: .8rem;
    margin-left: .3rem;
    position: relative;
    top: -1px;
    font-weight: 600;
  }
}
.nav-list {
  padding: 0 .8rem;
  >li {
    &.open {
      &:nth-of-type(1) {
        .navbar-item-list {
          height: 4.4rem;
        }
      }
      &:nth-of-type(2) {
        .navbar-item-list {
          height: 4.4rem;
        }
      }
      &:nth-of-type(3) {
        .navbar-item-list {
          height: 4.4rem;
        }
      }
      &:nth-of-type(4) {
        .navbar-item-list {
          height: 4.4rem;
        }
      }
      .navbar-plus  {
        transform: rotate(45deg);
      }
    }
  }
}
.navbar-item-top {
  height: 2.3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #484848;
}
.navbar-title {
  color: #fff;
  font-size: .6rem;
}
.navbar-plus {
  color: #F7F7F7;
  font-size: .7rem;
  transition: all .3s;
}
.navbar-item-list {
  padding-left: .8rem;
  height: 0;
  overflow: hidden;
  transition: all .3s;
  >li {
    height: 2.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #484848;
    color: #fff;
    font-size: .6rem;
  }
}
.contact-wrap {
  padding: 1.4rem .8rem 0;
  height: 11.25rem;
}
.contact-way-title {
  @include fh(.6rem);
  transform: scale(.8);
  color: #fff;
  position: relative;
  transform-origin: left center;
}
.contact-way-content {
  @include fh(.75rem);
  color: #fff;
  margin-top: .25rem;
}
.email-text {
  @include fh(.6rem);
  transform: scale(.95);
  color: rgba(255, 255, 255, .6);
  transform-origin: left center;
  margin-top: .9rem;
}
.address-text {
  @include fh(.6rem);
  transform: scale(.95);
  color: rgba(255, 255, 255, .6);
  transform-origin: left center;
  margin-top: .35rem;
}
.workday-text {
  @include fh(.6rem);
  transform: scale(.95);
  color: rgba(255, 255, 255, .6);
  transform-origin: left center;
  margin-top: .35rem;
}
.put-on-records {
  >a, span {
    @include fh(.6rem);
    transform: scale(.95);
    color: rgba(255, 255, 255, .6);
    transform-origin: left center;
    margin-top: .35rem;
  }
}
</style>
