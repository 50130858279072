import { isEmail, isIdCard, isPhoneNum, isUrl } from '@/utils/validate.js';
import { sleep, getUniqueId } from '@/utils/common.js';
import { parseQueryString, stringifyQueryString, getPath, Cookie2Json } from '@/utils/queryString.js';
import { filterSpecial, filterNumber } from '@/utils/filters.js';
import Drag from '@/utils/Drag.js';
// import { parseExcel, exportExcel} from '@/utils/excel.js';

export default {
  isEmail,
  isIdCard,
  isPhoneNum,
  isUrl,
  sleep,
  parseQueryString,
  stringifyQueryString,
  getPath,
  Cookie2Json,
  filterSpecial,
  filterNumber,
  Drag,
  getUniqueId,
  // parseExcel,
  // exportExcel
};
